<template>
  <master-layout smallTitle="Statistiche" bigTitle="Rete">
    <ion-grid class="mt-2 border-3-primary border-radius-client py-3 px-2">
      <ion-row>
        <ion-col size="12">
          <span class="font-bold">Filtra per:</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="5">
          <div class="flex flex-column align-items-start">
            <span class="font-bold">Oggetto</span>
            <ion-select
              @ionChange="handleOggettoChange($event)"
              interface="popover"
              placeholder="Seleziona..."
              label="Oggetto"
            >
              <ion-select-option value="visite">Visite</ion-select-option>
              <ion-select-option value="vendite"
                >Numero Vendite</ion-select-option
              >
              <ion-select-option value="valore"
                >Valore Vendite</ion-select-option
              >
            </ion-select>
          </div>
        </ion-col>
        <ion-col size="2"></ion-col>
        <ion-col size="5">
          <div class="flex flex-column align-items-start">
            <span class="font-bold">Periodo</span>
            <ion-select
              @ionChange="handlePeriodoChange($event)"
              interface="popover"
              placeholder="Seleziona..."
            >
              <ion-select-option value="Mese">Mese</ion-select-option>
              <ion-select-option value="Trimestre">Trimestre</ion-select-option>
              <ion-select-option value="Anno">Anno</ion-select-option>
            </ion-select>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid v-if="loading">
      <ion-row>
        <ion-col size="12"> Caricamento in corso... </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid v-else class="ion-padding-top ion-margin-top">
      <div
        v-for="item in items"
        :key="item.id"
        class="mb-4 item-card cursor-pointer"
        @click="goToUtenteOrdini(item.idAgente)"
      >
        <ion-grid class="w-full">
          <ion-row>
            <ion-col size="12">
              <span class="font-bold">{{ item.nomeAgente }}</span>
            </ion-col>
            <ion-col size="8">
              <span>Numero visite:</span>
            </ion-col>
            <ion-col size="4">
              <span> {{ item.numeroVisite }}</span>
            </ion-col>
            <ion-col size="8">Ordini confermati:</ion-col>
            <ion-col size="4">{{ item.ordiniConfermati }}</ion-col>
            <ion-col size="8">Totale periodo:</ion-col>
            <ion-col size="4">{{ item[keyGraficoToShow].totPeriodo }}</ion-col>
            <ion-col size="8">Valore Totale Ordini:</ion-col>
            <ion-col size="4">{{ item.valoreOrdini }} €</ion-col>
          </ion-row>
          <ion-row>
            <ion-col cols="12">
              <kpi-graph
                :prop_dataSet="[
                  {
                    data: item[keyGraficoToShow].kpiHistoryValue,
                    color: primaryColor,
                  },
                  {
                    data: item[keyGraficoToShow].kpiHistoryGoal,
                    color: secondaryColor,
                  },
                ]"
                :labelsValues="item[keyGraficoToShow].kpiHistoryLabel"
                :prop_options="{ fontColor: 'black', colorXY: 'black' }"
              ></kpi-graph>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-grid>
  </master-layout>
</template>

<script setup>
// TODO: Quello che va su numero visite va anche su totale periodo
// TODO:

import {
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import KpiGraph from "../../components/KpiGraph.vue";
import { ref, onMounted, getCurrentInstance, computed } from "vue";
import ApiService from "../../common/service.api";
import { useRouter } from "vue-router";
const router = useRouter();

const loading = ref(false);

let app = getCurrentInstance();
let whappyClientConfig =
  app.appContext.config.globalProperties.whappyClientConfig;
const primaryColor = whappyClientConfig.primaryColor;
const secondaryColor = whappyClientConfig.secondaryColor;
function getData() {
  loading.value = true;

  const url = `Ecommerce/getStats`;
  ApiService.get(url)
    .then((res) => {
      items.value = res.data;
      items.value.map((item) => {
        item.valore_Anno.totPeriodo = item.valore_Anno.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.valore_Mese.totPeriodo = item.valore_Mese.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.valore_Trimestre.totPeriodo =
          item.valore_Trimestre.kpiHistoryValue.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        item.vendite_Anno.totPeriodo = item.vendite_Anno.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.vendite_Mese.totPeriodo = item.vendite_Mese.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.vendite_Trimestre.totPeriodo =
          item.vendite_Trimestre.kpiHistoryValue.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        item.visite_Anno.totPeriodo = item.visite_Anno.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.visite_Mese.totPeriodo = item.visite_Mese.kpiHistoryValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        item.visite_Trimestre.totPeriodo =
          item.visite_Trimestre.kpiHistoryValue.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
      });
    })
    .finally(() => {
      loading.value = false;
    });
}

function goToUtenteOrdini(idUtente) {
  router.push({ name: "lista-ordini", params: { idUtente } });
}

const items = ref([]);

function handleOggettoChange(event) {
  console.log("handleOggettoChange", event.detail.value);
  graficoToShow.value[0] = event.target.value;
}

function handlePeriodoChange(event) {
  console.log("handlePeriodoChange", event.detail.value);
  graficoToShow.value[1] = event.target.value;
}

const graficoToShow = ref(["vendite", "Anno"]);
const keyGraficoToShow = computed(() => {
  return graficoToShow.value[0] + "_" + graficoToShow.value[1];
});

onMounted(() => {
  getData();
});
</script>

<style scoped>
.item-card {
  border: 3px solid var(--ion-color-primary);
  overflow: hidden;
  width: 100%;
  min-height: 120px;
  background: var(--client-ground1-color);
  border-radius: var(--ion-border-radius);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem 2rem;
}
</style>
